import { Customer } from "@/interfaces/customer";
import { customerService } from "@/services/customer.service";
import { Callback, TableSuccessModalResponse } from "helix-vue-components";
import find from "lodash/find";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./Customer.template.vue";

const namespace: string = "CustomerModule";
@Component({
  mixins: [Template]
})
export default class CustomerComponent extends Vue {
  @Action("checkInCustomer", { namespace })
  public checkInCustomerAction!: Callback;

  public back() {
    this.$router.push({ name: "check-in" });
  }

  public async checkInCustomer(arg: TableSuccessModalResponse) {
    if (
      (arg.item as Customer).status!.in_store_status === "PENDING_VERIFICATION"
    ) {
      this.verifyApiCreatedCustomer(arg.item as Customer);
    } else {
      this.checkInCustomerAction((arg.item as Customer).customer_id);
    }
  }

  protected async verifyApiCreatedCustomer(cus: Customer) {
    this.resetApiCreatedCustomerFlag(cus);
    const modalData = {
      acceptButton: "yes",
      cancelButton: "no",
      text: !this.isPatient(cus)
        ? "customer.caregiver_created_by_api"
        : "customer.customer_created_by_api"
    };
    const choice = await this.$modals.loadConfirmation(modalData, {
      positionX: "center",
      size: "small"
    });
    if (choice) {
      this.$store.dispatch("RouterModule/go", {
        name: "customers-edit",
        params: {
          id: cus.customer_id,
          currentModel: cus
        }
      });
    } else {
      this.checkInCustomerAction(cus.customer_id);
    }
  }

  protected resetApiCreatedCustomerFlag(aCustomer: Customer) {
    // We discard the created by API expendable flag
    customerService.resetCreatedByApiMark(aCustomer.customer_id!);
  }

  protected isPatient(customer: Customer) {
    return customer.profiles
      ? !!find(customer.profiles, ["profile_type", "PATIENT"])
      : false;
  }
}
